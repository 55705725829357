<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
  <div id="maincontent_container">
    <company-submenu></company-submenu>
    <div class="submenu-content">
      <p class="lead">Terms and Conditions</p>
      <p>
        <img src="@/assets/images/pdf.png" :height="50" :width="50">
        <a :href="'/'+item" class="blue_link" target="_blank">Download Terms and Conditions</a>
      </p>
      <br />
      <p class="lead">
        Supplemental Terms &amp; Conditions of Sale Re Federal Trade
        Restrictions.
      </p>
      <p>
        These Supplemental Terms &amp; Conditions of Sale Re Federal Trade
        Restrictions (herein “Supplemental Terms &amp; Conditions of Sale” or
        “Terms &amp; Conditions”) apply to any and all sales of IT Products made
        by Ma Laboratories, Inc. or its any of its affiliates (hereafter
        collectively “Ma Labs”)<sup>1</sup> to any customer or account of Ma
        Labs (hereafter “BUYER”) whether wholesale or for its own account, or
        for the account of, or resale directly or indirectly to or for the
        benefit of any customer or end-user of BUYER (or BUYER’s customer),
        including, more particularly and without limitation, any BUYER, its
        customers and end-users, whose procurement, purchase, requirements, or
        use of IT Products is subject to any federal trade restrictions, laws,
        rules, or regulations (hereafter “federal trade restrictions”). These
        Supplemental Terms &amp; Conditions of Sale incorporate by reference the
        General Terms &amp; Conditions of Sale of IT Products by Ma Labs to
        BUYER. See
        <router-link :to="{ name: 'TermsAndConditions' }"
          >https://www.malabs.com/terms-conditions</router-link
        >, incorporated herein by reference.
      </p>
      <br />
      <p>
        BUYER further agrees that all of Ma Labs’ Terms &amp; Conditions of
        Sale, including these Supplemental Terms and Conditions, whether printed
        on a purchase order or otherwise, supersede and take precedence over
        BUYER’s supplemental or conflicting terms and conditions, to which
        notice of objection is hereby given. Any conflicting statements or terms
        on BUYER’s purchase orders, invoices, confirmations or other documents
        generated by BUYER, or its customer or end-user, are negated by this
        Agreement in the absence of an express written agreement signed by a
        duly authorized officer of Ma Labs.
      </p>
      <br />
      <p>
        Ma Labs’s performance of any obligation to BUYER is expressly made
        conditional on BUYER’s acceptance and agreement to Ma Labs’s Terms &amp;
        Conditions of Sale in their entirety (including these Supplemental Terms
        &amp; Conditions of Sale Re Federal Trade Restrictions) unless otherwise
        expressly agreed to in writing by an officer of Ma Labs. In the absence
        of such an express written agreement signed by a duly authorized officer
        of Ma Labs, Ma Labs’s commencement of performance and/or delivery of
        Product shall be for BUYER’s convenience only and shall not be deemed or
        construed to be agreement to or acceptance of BUYER’s terms and
        conditions or any of them, including without limitation any terms,
        conditions, or requirements of sale re federal trade restrictions.
      </p>
      <br />
      <p>
        BUYER’s acceptance of any Product or Products shall be deemed acceptance
        of the terms and conditions stated herein. Acceptance by BUYER is
        limited to and conditioned upon BUYER’s assent to these terms and
        conditions. Neither Ma Labs’s commencement of performance or delivery
        shall be deemed or constituted as acceptance of BUYER’s supplemental or
        conflicting terms and conditions. BUYER’s acceptance of delivery of the
        Products and/or Services from Ma Labs, whether directly or indirectly,
        for itself, or for its customer or end-user, shall be deemed to
        constitute acceptance of the terms and conditions contained in these
        Supplemental Terms &amp; Conditions of Sale Re Federal Trade
        Restrictions and in Ma Labs’ General Terms &amp; Conditions of Sale in
        their entirety.
      </p>
      <br />
      <p>
        <b
          >THESE SUPPLEMENTAL TERMS AND CONDITIONS OF SALE RE FEDERAL TRADE
          RESTRICTIONS MAY ONLY BE WAIVED, MODIFIED, OR EXCUSED BY MEANS OF A
          WRITTEN AGREEMENT EXPRESSLY SO STATING THAT IS SIGNED BY A DULY
          AUTHORIZED OFFICER OF MA LABS.</b
        >
      </p>
      <br />
      <p class="foot">
        ________________________________________________________<br />
        <sup>1</sup>These Supplemental Terms and Conditions of Sale apply to Ma
        Labs, and any of its divisions, subsidiaries, and affiliated
        corporations, business entities, or persons, as well as to any
        third-party vendors and/or service providers of Ma Labs, that are
        subject to any federal trade restrictions.
      </p>
      <br />

      <p class="lead">Compliance With Export Control Laws.</p>
      <p>
        It is the policy of Ma Labs (and affiliates) as wholesale distributors
        to comply at all times with US export control laws with respect to its
        distribution and sale of goods, software, services, and technical
        information (hereafter collectively “IT Products”). BUYER acknowledges
        that its wholesale purchases of IT Products from Ma Labs (and
        affiliates) are subject to the
        <b>United States Export Administration Act</b> and all other federal
        export control laws, rules and regulations; this includes the
        prohibition on any direct or indirect transactions with persons and
        entities identified in the consolidated screening list maintained by the
        US Dept. of Commerce at
        <a
          href="https://www.trade.gov/consolidated-screening-list"
          target="_blank"
          >https://www.trade.gov/consolidated-screening-list</a
        >
        (collectively “US export control laws”). Ma Labs screens all invoiced
        transactions with BUYERS, including drop-shipments to BUYERS’ customers
        or end-users, against this database for compliance with US export
        control laws.
      </p>
      <br />
      <p>
        In purchasing IT Products from Ma Labs for its own account, for resale,
        or for drop-shipment to its customers or end-users, BUYER agrees to
        exercise due diligence to act in at all times in strict compliance with
        US export control laws. BUYER agrees that it shall not, directly or
        indirectly, purchase, sell (or resell), export, re-export, ship,
        trans-ship, transfer, or transmit any of the IT Products it has
        purchased from Ma Labs in contravention of US export control laws. These
        Supplemental Terms and Conditions extend to any drop-shipments made by
        Ma Labs to BUYER’s customers or end-users.
      </p>
      <br />
      <p>
        BUYER shall obtain any applicable federal governmental export control
        certificates, licenses, approvals, and waybills necessary to lawfully
        ship IT Products purchased wholesale from Ma Labs to BUYER or its
        customers or end-users. BUYER agrees to provide Ma Labs with written
        assurance of its compliance with US export control laws, and, as
        necessary, to obtain at its expense all documents, including export
        certificates, licenses, exceptions, approvals, and waivers, as may be
        required by the responsible federal departments, agencies, or officers,
        that regulate, administer, and enforce the US export control laws.
      </p>
      <br />
      <p>
        BUYER shall hold harmless and indemnify Ma Labs from any claim or damage
        arising out of or relating to any alleged violation of US export control
        laws, including payment of attorneys’ fees and costs incurred by Ma
        Labs, arising from or related to BUYER’s purchase of IT Products from Ma
        Labs, whether the purchase-sale is for BUYER’s account, or upon resale
        and/or drop-shipment to any of its customers or end-users.
      </p>
      <br />

      <p class="lead">Compliance With Anti-Moneylaundering Laws.</p>
      <p>
        It is the policy of Ma Labs (and affiliates) to comply at all times with
        applicable anti-moneylaundering (hereafter “AML”) laws. BUYERS must
        certify in writing their agreement to comply with all applicable
        provisions of the AML laws, and related requirements, rules, and
        regulations as a condition of opening their account with Ma Labs and in
        conducting any and all purchases of IT Products or services from Ma Labs
        or any of its affiliates, including the payment (and nature and source
        of payment) for said purchases. Failure to comply with the AML laws will
        result in immediate termination of BUYER’s account. Ma Labs (and
        affiliates) reserve the right to report any suspected violation of the
        AML laws to the appropriate federal, state, or local agency or
        department responsible for enforcement of applicable AML laws. BUYER
        must further agree to comply with all policies and procedures of Ma
        Labs’ Credit Department in opening their account (and as may be amended
        from time to time) and to comply with same in their payment for IT
        Products purchased from Ma Labs.
      </p>
      <br />

      <p class="lead">Compliance With Foreign Corrupt Practices Act.</p>
      <p>
        It is the policy of Ma Labs to comply at all times with the
        <b>Foreign Corrupt Practices Act</b> (“FCPA”). BUYERS must certify in
        writing their agreement to comply with all applicable provisions of the
        FCPA laws, and related requirements, rules, and regulations as a
        condition of opening their account with Ma Labs and in conducting any
        and all purchases of IT Products or services from Ma Labs or any of its
        affiliates, including the payment for said purchases. Failure to comply
        with the FCPA will result in immediate termination of BUYER’s account.
        Ma Labs (and its affiliates) reserve the right to report any suspected
        violation of the AML laws to the appropriate federal agency or
        department responsible for enforcement of applicable FCPA laws.
      </p>
      <br />

      <p class="lead">Compliance With Federal Trade Restrictions.</p>
      <p>
        It is the policy of Ma Labs to comply at all times with such federal
        trade restrictions as may be applicable, including but not limited to,
        the <b>Trade Agreements Act</b> (“TAA”), the
        <b>Buy American Act</b> (“BAA”), and any related requirements of the FAR
        (hereafter collectively “federal trade restrictions”). Ma Labs has
        adopted a Corporate Code of Conduct for its employees, informing them of
        potentially relevant federal trade restrictions, including the
        provisions of the TAA and BAA.
      </p>
      <br />
      <p>
        Ma Labs is a wholesale distributor of IT Products whose BUYERS purchase
        their goods wholesale from Ma Labs for their own account and resale to
        their customers and end-users.<br />
        In such capacities, many of the wholesale BUYERS of Ma Labs’ IT Products
        utilize EDI (electronic data interface) as the platform for their
        wholesale purchase-sale transactions including such transactions where
        Ma Labs undertakes to drop-ship such IT Products to BUYER’s customers or
        end-users as part of post-order logistical (shipping &amp; handling)
        service.
      </p>
      <br />
      <p>
        Ma Labs is not in privity with its BUYER (or its customers or end-users)
        as to BUYER’s resale to its customers or end-users of the IT Products it
        has purchased wholesale or for resale, or the terms, conditions, and
        legal and contractual requirements thereof. BUYER, and its corresponding
        customers or end-users, therefore at all times bear strict, sole, and
        exclusive responsibility for determining that the resale of IT Products
        to BUYER’s customers and end-users comply with all federal trade
        restrictions that may be applicable to such transactions or otherwise
        required by the terms of its purchase, procurement, or acquisition by
        its customers or end-users.<sup>2</sup>
      </p>
      <br />
      <p>
        Any drop-shipment made by Ma Labs of IT Products purchased by BUYER to a
        specific customer or end-user of BUYER is strictly a post-order
        logistical (shipping &amp; handling) service provided by Ma Labs
        pursuant to BUYER’s instruction. BUYER acknowledges that Ma Labs’
        drop-shipment per BUYER’s instruction does not give rise to or impose
        any legal duties or contractual obligations upon Ma Labs with respect to
        compliance with any federal trade restrictions that may govern BUYER’s
        sale/resale of such IT Products to its customers and end-users, and, as
        such, does not modify, alter, supersede, or in any way effect the duties
        and obligations of BUYER and its customers and end-users in their
        purchase-sale transaction to comply with federal trade restrictions.
      </p>
      <br />
      <p>
        As stated in the <b><a href="#notice">NOTICE</a></b> below, Ma Labs
        therefore disclaims, to the fullest extent permitted by law, any legal
        duty or contractual obligation of any kind to BUYER, or to any of its
        customers or end-users, with respect to the purchase-sale transactions
        that occur between them, or any of the terms, conditions, legal, or
        contractual requirements thereof.<br />
        It is the strict, sole, and exclusive responsibility of BUYER (and its
        customers and end-users purchasing/procuring IT Products from BUYER) to
        ensure that their respective resale and purchase/procurement of such IT
        Products are in full compliance with all federal trade restrictions
        (including product country-of-origin requirements) under the TAA, BAA,
        and any related requirements of the FAR.
      </p>
      <br />
      <p class="foot">
        ________________________________________________________<br />
        <sup>2</sup>More specifically, BUYER and its customers or end-users are
        strictly and solely responsible for determining compliance with federal
        trade restrictions as to any direct or indirect aggregation,
        integration, or bundling of IT Products, including, more particularly,
        certain peripherals (e.g., monitors, keyboards, mice, and docking
        stations, into IT solutions), by BUYER or its customers or end-users
        with respect to IT Products purchased by BUYER from Ma Labs, wholesale
        and/or for resale, that may not otherwise conform to or comply with
        federal trade restriction requirements.
      </p>
      <br />

      <p class="lead" id="notice"><u>NOTICE AND DISCLAIMER:</u></p>
      <p>
        <b
          >AS A WHOLESALE DISTRIBUTOR OF IT PRODUCTS FOR RESALE BY BUYER TO
          CUSTOMERS AND END-USERS NOT IN PRIVITY WITH MA LABS, MA LABS GENERALLY
          DISCLAIMS ANY LEGAL DUTY OR CONTRACTUAL OBLIGATION TO BUYER OR ITS
          CUSTOMERS OR END-USERS WITH RESPECT TO ENSURE THEIR COMPLIANCE WITH
          FEDERAL TRADE RESTRICTIONS IN THEIR PURCHASE-SALE TRANSACTIONS
          INVOLVING IT PRODUCTS THAT BUYER HAS PURCHASED FROM MA LABS.</b
        >
      </p>
      <br />
      <p>
        <b
          >THIS GENERAL DISCLAIMER APPLIES TO: (1) ALL OF BUYER’S WHOLESALE
          PURCHASES OF IT PRODUCTS FROM MA LABS; (2) TO ALL PURCHASES MADE BY
          BUYER OF IT PRODUCTS FOR RESALE TO ITS CUSTOMERS AND END-USERS; (3)
          ALL EDI TRANSACTIONS BY WHICH BUYER PURCHASES IT PRODUCTS FROM MA
          LABS; AND (4) ALL DROP-SHIPMENTS OF IT PRODUCTS MADE BY MA LABS AS
          PART OF ITS LOGISTICAL SERVICES (SHIPPING &amp; HANDLING) TO BUYER AS
          MAY BE REQUESTED.</b
        >
      </p>
      <br />
      <p>
        <b
          >MA LABS MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED,
          WITH RESPECT TO WHETHER ANY OF THE PRODUCTS IT SELLS ON THIS BASIS
          COMPLY WITH FEDERAL TRADE RESTRICTION. IT IS THE SOLE, EXCLUSIVE, AND
          STRICT RESPONSIBILITY OF BUYER, ITS CUSTOMERS, AND END-USERS TO COMPY
          WITH FEDERAL TRADE RESTRICTIONS IN THEIR PURCHASE-SALE TRANSACTIONS
          INVOLVING IT PRODUCTS SOLD BY MA LABS TO BUYER ON ANY OF THE ABOVE
          GROUNDS.</b
        >
      </p>
      <br />
      <p><b>EXCEPTIONS/PROTOCOL:</b></p>
      <br />
      <p>
        <b
          >IF BUYER REQUIRES THAT A PURCHASE OF A SPECIFIC IT PRODUCT FROM MA
          LABS FOR ITS OWN ACCOUNT, OR THAT OF ITS CUSTOMER OR END-USER, COMPLY
          WITH FEDERAL TRADE RESTRICTIONS, THE FOLLOWING RULES AND PROCEDURES
          MUST BE STRICTLY ADHERED TO BY BUYER, AND ITS CUSTOMERS AND END-USERS
          IN THE PLACEMENT OF THE ORDER, PRIOR TO AND AS A CONDITION PRECEDENT
          TO ITS ACCEPTANCE BY MA LABS:</b
        >
      </p>
      <ol>
        <li>
          <b
            >BUYER MUST NOTIFY MA LABS IN WRITING OF ANY REQUEST TO PURCHASE A
            SPECIFIC IT PRODUCT (BY ITS SKU) THAT IS SUBJECT TO FEDERAL TRADE
            RESTRICTIONS AND PROVIDE MA LABS WITH THE BASIS ON WHICH IT IS
            AUTHORIZED TO PURCHASE THE PRODUCT, FOR ITS OWN ACCOUNT OR FOR ITS
            CUSTOMER OR END-USER. BUYER MUST PLAINLY, CLEARLY, AND CONSPICUOUSLY
            IDENTIFY, COMMUNICATE, AND DOCUMENT ANY AND ALL SUCH FEDERAL TRADE
            RESTRICTIONS, REQUIREMENTS, AND TERMS AND CONDITIONS OF PURCHASE OF
            SUCH IT PRODUCTS FROM MA LABS IN ALL COMMUNICATIONS AND
            DOCUMENTATION PROVIDED TO MA LABS.</b
          >
        </li>
        <li>
          <b
            >BUYER MUST PROVIDE MA LABS WITH SUFFICIENT FACTS (AND WRITTEN
            DOCUMENTATION) TO SUBSTANTIATE THAT ITS PROPOSED PURCHASE OF THE
            PRODUCT IS COMPLIANT UNDER THE APPLICABLE FEDERAL TRADE RESTRICTION,
            WHETHER THE SUBMITTED BASIS FOR COMPLIANCE IS PREDICATED ON
            COUNTRY-OF-ORIGIN OF THE PRODUCT; THE NATURE OF THE PRODUCT IN
            RELATION TO THE CRITERIA OF REGULATIONS OR CONTRACT SPECIFICATIONS
            (E.G., THE BUNDLING, AGGREGATION RULES); OR THE PURCHASING
            THRESHOLDS; OR OTHERWISE BASED ON MANUFACTURER, VENDOR, SUPPLIER, OR
            government approvals, certificates, consents, PROOFS OF
            REGISTRATION, exemption, EXCEPTION, or waiver covering the specific
            it product AT ISSUE. THIS INCLUDES BUYER OBTAINING SAME FROM BUYER,
            ITS CUSTOMER, OR THE END-USER, INCLUDING ANY PROCUREMENT OR
            PURCHASING OFFICER, AGENT, OR REPRESENTATIVE OF BUYER, ITS CUSTOMER,
            OR THE END-USER.</b
          >
        </li>
        <li>
          <b
            >BUYER MUST OBTAIN THE WRITTEN APPROVAL OF A DULY AUTHORIZED OFFICER
            OF MA LABS (NOT ANY ACCOUNT REPRESENTATIVE, AGENT, OR EMPLOYEE OF MA
            LABS) GRANTING AUTHORIZING THE PROPOSED PURCHASE ON THE BASES
            PROVIDED BY BUYER TO MA LABS PRIOR TO SUBMISSION OF ITS PURCHASE
            ORDER.<sup>3</sup></b
          >
        </li>
        <li>
          <b
            >THE PURCHASE ORDER PLACED BY BUYER, FOR ITS OWN ACCOUNT, ITS
            CUSTOMER, OR END-USER, SHALL EXPRESSLY IDENTIFY THE PRODUCT (SKU)
            THE SALE OF WHICH HAS BEEN ESTABLISHED TO COMPLY WITH THE PERTINENT
            FEDERAL TRADE RESTRICTION; AND SUCH FEDERAL TRADE RESTRICTIONS MUST
            BE EXPRESSLY IDENTIFIED ON THE PRE-APPROVED PURCHASE ORDER AND
            CONFIRMED ON MA LABS’ INVOICE AFTER OBTAINING WRITTEN APPROVAL FROM
            A DULY AUTHORIZED OFFICER OF MA LABS.</b
          >
        </li>
      </ol>
      <p></p>
      <br />
      <p>
        <b
          >ANY AND ALL PURCHASE ORDERS PLACED BY BUYER, WHETHER FOR ITS OWN
          ACCOUNT OR THAT OF ANY CUSTOMER OR END-USER OF BUYER, WITHOUT STRICT
          COMPLIANCE WITH THE ABOVE REQUIREMENTS, ARE NULL AND VOID, AND SUBJECT
          TO RESCISSION BY MA LABS.</b
        >
      </p>
      <br />
      <p>
        IF BUYER FAILS TO COMPLY WITH ANY OF THE TERMS OF THE
        <b><a href="#notice">NOTICE</a></b> AND RELATED PARAGRAPHS IN THIS
        SECTION OF THE SUPPLEMENTAL TERMS &amp; CONDITIONS, OR ANY PART THEREOF,
        MA LABS ASSUMES NO DUTY, LEGAL OR CONTRACTUAL FOR SAME, DISCLAIMS ANY
        LIABILITY FOR ANY TAA COMPLIANCE REQUIREMENTS, AND, IN SUCH EVENT, SHALL
        HAVE NO LIABILITY FOR PROVIDING NON‐TAA‐COMPLIANT (OR OTHER NON-FEDERAL
        TRADE RESTRICTION) PRODUCTS TO BUYER OR TO ITS CUSTOMERS OR END-USERS.
      </p>
      <br />
      <p>
        <b><u>Further Rights and Remedies:</u></b> Any failure to comply with,
        or other direct or indirect contravention of the foregoing Supplemental
        Terms &amp; Conditions by BUYER or its customers or end-users, shall
        render the subject purchase-sales transaction void or voidable at the
        sole option of Ma Labs, including the remedy of rescission and
        restitution, including a substitution of goods or a complete unwinding
        of the transaction, with return of the subject Products to Ma Labs; and
        at the expense of BUYER or its customer or end-user, as may be fair
        equitable and according to proof, in the event they are determined to be
        at fault. Ma Labs may, in its sole discretion, also terminate BUYER’s
        account. All other rights reserved.
      </p>
      <br />
      <p class="foot">
        ________________________________________________________<br />
        <sup>3</sup>BUYER (and its customers or end-users) may not rely on any
        oral or written representations, assurances, or statements of any
        account representative, employee, or agent of Ma Labs that purport to
        authorize, permit, validate, ratify, or effect any purchase or sale of
        IT Products subject to any federal trade restriction, including that
        such Product is compliant with the TAA or other federal trade
        restriction; such statements are not binding on Ma Labs in the absence
        of written agreement and approval by a duly authorized officer of the
        Company.
      </p>
      <br />

      <p class="lead">Other Regulatory Requirements, Terms or Conditions.</p>
      <p>
        <b
          >BUYER SHALL AND MUST ENSURE THAT ANY AND ALL REGULATORY REQUIREMENTS,
          TERMS OR CONDITIONS OF ITS PURCHASE OF IT PRODUCTS FROM MA LABS, OR
          ITS RESALE OF SUCH PRODUCTS TO ANY CUSTOMER/END-USER OF BUYER, ARE
          DISCLOSED TO MA LABS IN WRITING AT THE TIME OF ITS SOLICITATION OR
          REQUEST FOR QUOTE; ARE SUBSEQUENTLY ACKNOWLEDGED AND AGREED TO IN
          WRITING BY AN OFFICER OF MA LABS IN WRITING PRIOR TO APPROVAL OF ANY
          PURCHASE ORDER; AND THAT ANY SUCH TERMS ARE CALLED OUT PLAINLY,
          CLEARLY, AND CONSPICUOUSLY ON BUYER’S PURCHASE ORDER AND ALL OTHER
          DOCUMENTATION PERTAINING TO THE PROPOSED TRANSACTION.</b
        >
      </p>
      <br />
      <p>
        If BUYER fail to comply with any of the terms of the preceding
        <b><a href="#notice">NOTICE</a></b> paragraphs or any part thereof
        (including, more particularly, if a duly authorized officer of Ma Labs
        makes no prior written acknowledgement of the regulatory requirements),
        Ma Lab assumes no duty, legal or contractual for same, disclaims any
        liability for any regulatory compliance requirement, and, in such event,
        shall have no liability for providing non‐regulatory compliant Products
        or Services to BUYER or to its customers or end-users. BUYER (and its
        customers or end-users) may not rely on any oral or written
        representations, assurances, or statements of any account
        representative, agent, or employee of Ma Labs that purport to authorize,
        permit, validate, ratify, or effect any purchase or sale of IT Products
        subject to any regulatory restriction, including that such Product is
        compliant with the regulatory requirements, terms, as they are not
        binding on Ma Labs.
      </p>
      <br />
      <p>
        Compliance with such regulatory requirements, if any, is further
        conditional and dependent upon the nature, cost, and burden associated
        with the requested requirement and the nature and price of the IT
        Product for which such regulatory compliance is being solicited. In
        addition, compliance with one or more of these requirements may require
        an additional charge to BUYER, which will be included in its Ma Labs
        quote and invoice.
      </p>
      <br />
      <p>
        <b><u>Further Remedies And Reservation of Rights by Ma Labs:</u></b> Any
        failure to comply with, or other direct or indirect contravention of the
        foregoing Supplemental Terms &amp; Conditions by BUYER or its customers
        or end-users, shall render the subject purchase-sales transaction void
        or voidable at the sole option of Ma Labs, including the remedy of
        rescission and restitution, including a substitution of compliant goods
        or a complete unwinding of the transaction, with return of the subject
        IT Products to Ma Labs; and at the expense of BUYER or its customer or
        end-user, as may be fair and equitable, and according to proof, in the
        event that they are determined to be at fault. Ma Labs may, in its sole
        discretion, also terminate BUYER’s account. All other rights reserved.
      </p>
      <br />
    </div>
  </div>
</template>

<script>
import CompanySubmenu from "../../components/nav/CompanySubmenu.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { getAPI } from "../../utils/axios-api";
import { ref, onMounted } from "vue";

export default {
  name: "TermsAndConditionsSuppl",
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const item = ref({});

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Terms and Conditions", link: "TermsAndConditionsSuppl"})

    onMounted(() => {
      getAPI.get("/content/pages/63").then((response) => {
        item.value = response.data.body[0].value;
      });
    });

    return { item, breadcrumb_links };
  },
};
</script>

<style scoped>
.submenu-content {
  float: left;
  width: calc(100% - 300px);
  padding: 0 30px 30px;
}
p.normal {
  margin-bottom: 30px;
}
</style>
